import CreateNewInvoice from './CreateNewInvoice';
import './CreateNewInvoicePopup';
import Popup from '../../../../components/ui/Popup';

function CreateNewInvoicePopup({
    view,
    setView,
    invoiceToBeUpdated,
    userData,
    refreshData
}) {

    return (
        <Popup
            view={view}
            setView={setView}
            width={'1098px'}
            xOverflow='overflow-x-scroll'
            yOverflow='overflow-y-scroll'
            maxHeight='45rem'
        >
            <CreateNewInvoice
                setIsInvoicePopupOpen={setView}
                setView={setView}
                userData={userData}
                invoiceToBeUpdated={invoiceToBeUpdated}
                refreshData={refreshData}
            />

        </Popup>
    );
}

export default CreateNewInvoicePopup;
