import { createBrowserHistory } from '@remix-run/router';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { DealActions } from '../../actions';
import Layout from '../../components/layout';
import Popup from '../../components/ui/Popup/Popup';
import { Toast, notify } from '../../components/ui/Toast/Toast';
import DealsDataSection from './components/dealComponents/DealsDataSection';
import { dealsTableConfig } from './configs/dealConfigs/dealsTableConfig';
import NewDealPopup from './popups/dealPopups/NewDealPopup';

function DealsPage() {
    // States
    const [dealsDataTable, setDealsDataTable] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [newDealPopupView, setNewDealPopupView] = useState(false)
    const [paginationMetadata, setPaginationMetadata] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30
    });
    const [filterQuery, setFilterQuery] = useState('');

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const navigate = useNavigate();

    // Hooks
    const location = useLocation();
    const history = createBrowserHistory();

    const fetchDealsData = async (page) => {
        setLoadingTable(true);
        setDealsDataTable([]);

        const result = await DealActions.getAllDeals(userData, `&page_size=${paginationMetadata.pageSize}&display=${userData.userProfileName}${filterQuery}`, page);

        if (result.success) {
            setDealsDataTable(result.data);
            setPaginationMetadata(prevState => ({ ...prevState, currentPage: page }));

            if (paginationMetadata.totalItems !== result.metadata.count) {
                setPaginationMetadata(prevState => ({ ...prevState, totalItems: result.metadata.count }));
            }
        }
        else {
            notify('Erreur de récuperation des affaires');
        }

        setLoadingTable(false);
    }

    useEffect(() => {
        fetchDealsData(1);
    }, [filterQuery])

    // Check navigation message existence
    useEffect(() => {
        if (location.state === 'invalid_id') {
            notify('Affaire inexistante');
        }
        else if (location.state === 'deal_deleted') {
            notify('Affaire supprimée');
        }
        history.replace({ ...history.location, state: null });
    }, []);

    const handleMissionNavigation = async (dealId) => {
        const getDeal = await DealActions.getDealById(userData, dealId);

        if (getDeal.success) {

            const isSale = getDeal.data.sales.find((sale) => sale.ressource.id === userData.userId) ? true : false;

            if (userData.role === 'CO' && getDeal.data.production_lead.id !== userData.userId && !isSale) {

                navigate(`/deals/${getDeal.data.id}/missions/${getDeal.data.missions.find((mission) => mission.ressource.id === userData.userId)?.id}`);

                return;
            }
            else {
                navigate(`/deals/${dealId}`);
            }
        }

    }

    const filterDealList = async (query) => {
        setFilterQuery(`&${query}`);
    }
    
    // Configs
    const tableConfig = dealsTableConfig(handleMissionNavigation, filterDealList, userData);

    return (
        <Layout>
            <Toast/>
            <DealsDataSection
                data={dealsDataTable}
                tableConfig={tableConfig}
                tableLoading={loadingTable}
                handleButtonClick={() => setNewDealPopupView(true)}
                paginationMetadata={paginationMetadata}
                onTablePageChange={fetchDealsData}
            />
            <Popup
                view={newDealPopupView}
                setView={setNewDealPopupView}
                width={'1150px'}
                yOverflow='overflow-y-scroll'
                xOverflow=''
            >
                <NewDealPopup
                    setView={setNewDealPopupView}
                    userData={userData}
                />
            </Popup>
        </Layout>
    );
}

export default DealsPage;
