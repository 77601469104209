import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Tooltip from 'antd/lib/tooltip';
import deleteIcon from '../../../../../../assets/icons/actionsbutton/delete.png';
import deleteIconBlue from '../../../../../../assets/icons/blueStyle/delete-blue.png';
import DateInput from '../../../../../../components/ui/Inputs/DateInput/DateInput';
import SelectInput from '../../../../../../components/ui/Inputs/SelectInput/SelectInput';
import TextInput from '../../../../../../components/ui/Inputs/TextInput';
import { frenchFormat } from '../../../../../../utils/dateFormatting';
// --------------------------------------------
// ↓ Funciton Body ↓
// --------------------------------------------

const BudgetAndProductionModule = ({
    dealData,
    control,
    disabled,
    watch,
    partnerValues,
    productionRessourcesValues,
    pilotsValues,
    setPilotsValues,
    setProductionRessourcesValues,
    handleGetRessources,
    ressources,
    blueStyle,
    accordCadreValue
}) => {
    const [partnerLabels, setPartnerLabels] = useState([]);

    const [totalInterventionDays, setTotalInterventionDays] = useState(0);
    const [totalBudget, setTotalBudget] = useState(0);

    const [budgetLabel, setBudgetLabel] = useState('');

    const dealTypeValue = dealData?.type ? dealData.type : watch('deal_type')?.value;
    const subsidiaryValue = dealData?.subsidiary ? dealData.subsidiary : watch('subsidiary')?.value;
    const productionLeadValue = watch('production_lead');

    console.log("productionLeadValue", productionLeadValue);

    const vat = useSelector((state) => state.GlobalsReducer.vat);

    useEffect(() => {
        if (!partnerValues[0].ressource) return;
        setPartnerLabels(partnerValues.map((partner) => partner.ressource?.name || []));
    }, [partnerValues])

    // --------------------------------------------
    // ↓ Rerender form to update response grouping label on change of subsidiary ↓
    // --------------------------------------------
    useEffect(() => {
        if (!subsidiaryValue) return;
        setBudgetLabel(subsidiaryValue?.name === "Terre d'Avance Formation" ? "Terre d'Avance Formation" : "Terre d'Avance");
    }, [subsidiaryValue])

    const handleAddProductionRessources = () => {
        if (productionRessourcesValues.length < 10) {
            setProductionRessourcesValues((prevState) => [...prevState, { id: Math.random(), role: '', ressource: null }]);
        }
    }

    const handleRemoveProductionRessources = (id) => {
        setProductionRessourcesValues((prevState) => prevState.filter((element) => element.id !== id));
    }

    const handleProductionRessourcesValueChange = (e, id, type) => {
        setProductionRessourcesValues((prevState) => prevState.map((el) => {
            if (el.id === id) {
                const updatedValue = { ...el, [type]: e };

                const intervention_days = parseInt(updatedValue.intervention_days) || 0;
                const dailyRate = parseInt(updatedValue.daily_rate) || 0;
                const budget = Math.round(intervention_days * dailyRate);

                return { ...updatedValue, intervention_days: intervention_days, daily_rate: dailyRate, budget: budget };
            }

            return el;
        }));
    };
    const handleAddPilots = () => {
        if (pilotsValues.length < 10) {
            setPilotsValues((prevState) => [...prevState, { id: Math.random(), daily_rate: '', ressource: null }]);
        }
    }

    const handleRemovePilots = (id) => {
        setPilotsValues((prevState) => prevState.filter((element) => element.id !== id));
    }

    // --------------------------------------------
    // ↓ Dynamically calculate totals for Production Section ↓
    // --------------------------------------------
    useEffect(() => {

        const newTotalInterventionDays = productionRessourcesValues.reduce((acc, curr) => {
            const totalInterventionDays = acc + parseInt(curr.intervention_days ? curr.intervention_days : 0);

            return totalInterventionDays;
        }, 0);

        const newTotalBudget = productionRessourcesValues.reduce((acc, curr) => {
            const totalBudget = acc + parseInt(curr.budget ? curr.budget : 0);

            return totalBudget;
        }, 0);

        setTotalInterventionDays(newTotalInterventionDays);
        setTotalBudget(newTotalBudget);

    }, [productionRessourcesValues])

    useEffect(() => {
        if (productionLeadValue) {
            setProductionRessourcesValues((prevState) => prevState[0] = [{ id: Math.random(), ressource: productionLeadValue, daily_rate: 0, intervention_days: 0, budget: 0 }]);
        }
    }, [productionLeadValue])

    const filterOutAlreadySelected = (options, selected) => {
        if (!options) return;
        
        const filteredArray = options.filter(obj1 =>
            !selected.some(obj2 =>
                obj1?.id === obj2?.ressource?.id
            )
        );

        return filteredArray;
    };

    const filterOutPilots = (options) => {

        const pilotValuesArray = pilotsValues.map((pilot, index) => {
            const watchPilot = watch(`agreement_lead_${index}`);

            if (watchPilot) {
                return watchPilot;
            }
        });

        if (!pilotValuesArray[0]) return options;

        var selected = pilotValuesArray.filter(Boolean);

        // Corrected filter function with explicit return statement
        const filteredArray = options.filter((pilotOption) => {
            return !selected.some(obj2 =>
                pilotOption?.id === obj2?.id
            );
        });

        return filteredArray;
    };

    const isACBC = dealTypeValue === "framework" || dealTypeValue === "purchase_order";

    return (
        <>
            <div className="flex justify-start text-gradient w-full pb-2 pl-2 mb-6 pt-4 border-b border-gray-200">
                Budget
            </div>

            {isACBC ?
                <div className='w-full'>
                    <div className='w-full pb-4 mb-4 border-b border-gray-200'>
                        <div className="w-full flex items-center justify-between pb-1 gap-4">
                            <label className="w-[170px] font-normal text-sm text-[#646464]">{budgetLabel ? budgetLabel : "Terre d'Avance"}
                            </label>
                            <div className='flex items-center'>
                                <Controller
                                    name="min_budget"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Veuillez saisir le budget'
                                        }
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error }
                                    }) => (
                                        <TextInput
                                            width='w-[200px]'
                                            labelWidth='w-[80px]'
                                            label="Minimum"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Budget HT"
                                            type="number"
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    € HT
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Controller
                                    name="min_days_of_intervention"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Veuillez saisir le nombre des jours'
                                        }
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error }
                                    }) => (
                                        <TextInput
                                            width='w-[150px]'
                                            label=''
                                            type="number"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Jours d'intervention"
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    Jours
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Controller
                                    name="min_vat_rate"
                                    control={control}
                                    // rules={{
                                    //     required: {
                                    //         value: true,
                                    //         message: 'Veuillez saisir la tva'
                                    //     }
                                    // }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error }
                                    }) => (
                                        <TextInput
                                            width='w-[100px]'
                                            label=''
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Taux TVA"
                                            type="number"
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    % TVA
                                </label>
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-between pb-1 gap-4">
                            <label className="w-[170px] font-normal text-sm text-[#646464]"></label>
                            <div className='flex items-center'>
                                <Controller
                                    name="max_budget"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Veuillez saisir le budget'
                                        }
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error }
                                    }) => (
                                        <TextInput
                                            width='w-[200px]'
                                            labelWidth='w-[80px]'
                                            label="Maximum"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Budget HT"
                                            type="number"
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    € HT
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Controller
                                    name="max_days_of_intervention"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Veuillez saisir le nombre des jours'
                                        }
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error }
                                    }) => (
                                        <TextInput
                                            width='w-[150px]'
                                            label=''
                                            type="number"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Jours d'intervention"
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    Jours
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Controller
                                    name="max_vat_rate"
                                    control={control}
                                    // rules={{
                                    //     required: {
                                    //         value: true,
                                    //         message: 'Veuillez saisir la tva'
                                    //     }
                                    // }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error }
                                    }) => (
                                        <TextInput
                                            width='w-[100px]'
                                            label=''
                                            onChange={onChange}
                                            value={value ? value : vat}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Taux TVA"
                                            type="number"
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    % TVA
                                </label>
                            </div>
                        </div>
                    </div>
                    {partnerValues[0]?.ressource ? <div className='w-full pb-4 mb-4 border-b border-gray-200'>
                        {partnerValues.map((partner, index) => {
                            return (
                                <div key={partner.id} className="w-full flex justify-between pb-1 gap-4">
                                    <div className='flex items-center'>
                                        <Controller
                                            name={`partner_budget_${partner.ressource?.name ? partner.ressource?.name : `partner_${index}`}`}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Veuillez saisir le budget'
                                                }
                                            }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error }
                                            }) => (
                                                <TextInput
                                                    // width='w-[100px]'
                                                    label={typeof (partnerLabels[index]) === "string" ? partnerLabels[index] : 'Partenaire'}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={error}
                                                    required={true}
                                                    disabled={disabled}
                                                    placeholder="Budget HT"
                                                    type="number"
                                                    labelWidth='w-56'
                                                />
                                            )}
                                        />
                                        <label className="font-normal text-sm text-[#646464] pl-1 ">
                                            € HT
                                        </label>
                                    </div>
                                    <div className='flex items-center'>
                                        <Controller
                                            name={`payment_${partner.ressource?.name ? partner.ressource?.name : `partner_${index}`}`}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Veuillez saisir le nombre des jours'
                                                }
                                            }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error }
                                            }) => (
                                                <SelectInput
                                                    // width='w-[100px]'
                                                    label=''
                                                    options={
                                                        [{ id: 1, direct: true, label: 'Direct' },
                                                            { id: 2, direct: false, label: 'Indirect' }]
                                                    }
                                                    labelKeys={['label']}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={error}
                                                    required={true}
                                                    disabled={disabled}
                                                    placeholder="Paiement"
                                                    type="number"
                                                // labelWidth='w-40'
                                                />
                                            )}
                                        />
                                        <label className="font-normal text-sm text-[#646464] pl-1 ">
                                            Jours
                                        </label>
                                    </div>
                                    <div className='flex items-center'>
                                        <Controller
                                            name={`vat_rate_${partner.ressource?.name ? partner.ressource?.name : `partner_${index}`}`}
                                            control={control}
                                            // rules={{
                                            //     required: {
                                            //         value: true,
                                            //         message: 'Veuillez saisir la tva'
                                            //     }
                                            // }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error }
                                            }) => (
                                                <TextInput
                                                    width='w-[100px]'
                                                    label=''
                                                    onChange={onChange}
                                                    value={value ? value : vat}
                                                    error={error}
                                                    required={true}
                                                    disabled={disabled}
                                                    placeholder="Taux TVA"
                                                    type="number"
                                                />
                                            )}
                                        />
                                        <label className="font-normal text-sm text-[#646464] pl-1 ">
                                            % TVA
                                        </label>
                                    </div>
                                </div>
                            )
                        })}
                    </div> : null}
                </div>
                :
                <div className='w-full'>
                    <div className='w-full pb-4 mb-4 border-b border-gray-200'>
                        <div className="w-full flex justify-between pb-1 gap-4">
                            <div className='flex items-center'>
                                <Controller
                                    name="budget_global"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Veuillez saisir le budget'
                                        }
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error }
                                    }) => (
                                        <TextInput
                                            // width='w-[100px]'
                                            label={budgetLabel || "Terre d'Avance"}
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Budget HT"
                                            type="number"
                                            labelWidth='w-56'
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    € HT
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Controller
                                    name="billable_days_global"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Veuillez saisir le nombre des jours'
                                        }
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error }
                                    }) => (
                                        <TextInput
                                            // width='w-[100px]'
                                            label=''
                                            type="number"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Jours d'intervention"
                                        // labelWidth='w-40'
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    Jours
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Controller
                                    name="vat_rate_global"
                                    control={control}
                                    // rules={{
                                    //     required: {
                                    //         value: true,
                                    //         message: 'Veuillez saisir la tva'
                                    //     }
                                    // }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error }
                                    }) => (
                                        <TextInput
                                            width='w-[100px]'
                                            label=''
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Taux TVA"
                                            type="number"
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                % TVA
                                </label>
                            </div>
                        </div>
                        <div className="w-full flex justify-between pb-1 gap-4">
                            <div className='flex items-center'>
                                <Controller
                                    name="expense_budget"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Veuillez saisir le budget'
                                        }
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error }
                                    }) => (
                                        <TextInput
                                            // width='w-[100px]'
                                            label="Achats forfaitaires refacturés"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Achats forfaitaires refacturés"
                                            type="number"
                                            labelWidth='w-56'
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    € HT
                                </label>
                            </div>
                        </div>
                        <div className="w-full flex justify-between pb-1 gap-4">
                            <div className='flex items-center'>
                                <Controller
                                    name="procurement_cost"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Veuillez saisir le budget'
                                        }
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error }
                                    }) => (
                                        <TextInput
                                            // width='w-[100px]'
                                            label="Frais forfaitaires refacturés"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Frais forfaitaires refacturés"
                                            type="number"
                                            labelWidth='w-56'
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    € HT
                                </label>
                            </div>
                        </div>
                        <div className="w-full flex justify-between pb-1 gap-4">
                            <div className='flex items-center'>
                                <Controller
                                    name="commission"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Veuillez saisir le budget'
                                        }
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error }
                                    }) => (
                                        <TextInput
                                            // width='w-[100px]'
                                            label="Commissions"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Comissions"
                                            type="number"
                                            labelWidth='w-56'
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    € HT
                                </label>
                            </div>
                        </div>
                        {accordCadreValue ?
                            <div className='w-full pb-4 mb-4 border-b border-gray-200'>
                                {pilotsValues.map((pilot, index) => {
                                    return (
                                        <div key={pilot.id} className='relative'>
                                            <div className="w-full flex justify-between gap-4 mb-1">
                                                <div className='flex items-center'>
                                                    <Controller
                                                        name={`agreement_budget_${index}`}
                                                        control={control}
                                                        // rules={{
                                                        //     required: {
                                                        //         value: true,
                                                        //         message: 'Veuillez saisir le budget'
                                                        //     }
                                                        // }}
                                                        render={({
                                                            field: { onChange, value },
                                                            fieldState: { error }
                                                        }) => (
                                                            <TextInput
                                                                label="Budget pilotage"
                                                                onChange={onChange}
                                                                value={value}
                                                                error={error}
                                                                required={true}
                                                                disabled={disabled}
                                                                placeholder="Budget HT"
                                                                type="number"
                                                                labelWidth='w-56'
                                                            />
                                                        )}
                                                    />
                                                    <label className="font-normal text-sm text-[#646464] pl-1 ">
                                                        € HT
                                                    </label>
                                                </div>
                                                <div>
                                                    <Controller
                                                        name={`agreement_lead_${index}`}
                                                        control={control}
                                                        // rules={{
                                                        //     required: {
                                                        //         value: true,
                                                        //         message: 'Veuillez sélectionner une ressource'
                                                        //     }
                                                        // }}
                                                        render={({
                                                            field: { onChange, value, ref },
                                                            fieldState: { error }
                                                        }) => (
                                                            <SelectInput
                                                                labelWidth='w-[60px]'
                                                                // label='Pilote'
                                                                placeholder='Pilote'
                                                                inputRef={ref}
                                                                value={value}
                                                                error={error}
                                                                options={filterOutPilots(accordCadreValue.producers)}
                                                                hasNextPage={ressources?.hasNextPage}
                                                                loading={!accordCadreValue.producers}
                                                                onChange={onChange}
                                                                isSearchable={true}
                                                                fetchFunction={handleGetRessources}
                                                                labelKeys={['first_name', 'last_name']}
                                                                required={true}
                                                                disabled={disabled}
                                                                blueStyle={blueStyle}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className='flex items-center'>
                                                    <Controller
                                                        name={`agreement_daily_rate_${index}`}
                                                        control={control}
                                                        // rules={{
                                                        //     required: {
                                                        //         value: true,
                                                        //         message: 'Veuillez saisir le budget'
                                                        //     }
                                                        // }}
                                                        render={({
                                                            field: { onChange, value },
                                                            fieldState: { error }
                                                        }) => (
                                                            <TextInput
                                                                onChange={onChange}
                                                                value={value}
                                                                error={error}
                                                                required={true}
                                                                disabled={disabled}
                                                                placeholder="TJM"
                                                                type="number"
                                                                width='w-[100px]'
                                                                // labelWidth='w-56'
                                                            />
                                                        )}
                                                    />
                                                    <label className="font-normal text-sm text-[#646464] pl-1 ">
                                                        € HT
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='absolute right-[-24px] top-[6px] flex justify-between'>
                                                {
                                                    index === 0 ?
                                                        null
                                                        : <button
                                                            onClick={() => handleRemovePilots(pilot.id)}
                                                            disabled={disabled}
                                                            className="flex items-center justify-center bg-transparent"
                                                        >
                                                            <Tooltip title='Supprimer'>
                                                                <div>
                                                                    <img src={blueStyle ? deleteIconBlue : deleteIcon} alt="logo" className="w-[18px]"/>
                                                                </div>
                                                            </Tooltip>
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                                <Tooltip title='Ajouter une ressource'>
                                    <div
                                        onClick={handleAddPilots}
                                        className={` cursor-pointer text-sm text-gradient ${pilotsValues.length < 10 ? "text-[#646464]" : "text-[#b2bec3]"} mt-1`}
                                    >
                                        <p className='hover:underline'>Ajouter un pilote</p>
                                    </div>
                                </Tooltip>
                            </div>
                            : null
                        }
                    </div>
                    {partnerValues[0]?.ressource ? <div className='w-full pb-4 mb-4 border-b border-gray-200'>
                        {partnerValues.map((partner, index) => {
                            return (
                                <div key={partner.id} className="w-full flex justify-between pb-1 gap-4">
                                    <div className='flex items-center'>
                                        <Controller
                                            name={`partner_budget_${partner.ressource?.name ? partner.ressource?.name : `partner_${index}`}`}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Veuillez saisir le budget'
                                                }
                                            }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error }
                                            }) => (
                                                <TextInput
                                                    // width='w-[100px]'
                                                    label={typeof (partnerLabels[index]) === "string" ? partnerLabels[index] : 'Partenaire'}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={error}
                                                    required={true}
                                                    disabled={disabled}
                                                    placeholder="Budget HT"
                                                    type="number"
                                                    labelWidth='w-56'
                                                />
                                            )}
                                        />
                                        <label className="font-normal text-sm text-[#646464] pl-1 ">
                                            € HT
                                        </label>
                                    </div>
                                    <div className='flex items-center'>
                                        <Controller
                                            name={`payment_${partner.ressource?.name ? partner.ressource?.name : `partner_${index}`}`}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Veuillez saisir le nombre des jours'
                                                }
                                            }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error }
                                            }) => (
                                                <SelectInput
                                                    // width='w-[100px]'
                                                    label=''
                                                    options={
                                                        [{ id: 1, direct: true, label: 'Direct' },
                                                            { id: 2, direct: false, label: 'Indirect' }]
                                                    }
                                                    labelKeys={['label']}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={error}
                                                    required={true}
                                                    disabled={disabled}
                                                    placeholder="Paiement"
                                                    type="number"
                                                // labelWidth='w-40'
                                                />
                                            )}
                                        />
                                        <label className="font-normal text-sm text-[#646464] pl-1 ">
                                            Jours
                                        </label>
                                    </div>
                                    <div className='flex items-center'>
                                        <Controller
                                            name={`vat_rate_${partner.ressource?.name ? partner.ressource?.name : `partner_${index}`}`}
                                            control={control}
                                            // rules={{
                                            //     required: {
                                            //         value: true,
                                            //         message: 'Veuillez saisir la tva'
                                            //     }
                                            // }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error }
                                            }) => (
                                                <TextInput
                                                    width='w-[100px]'
                                                    label=''
                                                    onChange={onChange}
                                                    value={value ? value : vat}
                                                    error={error}
                                                    required={true}
                                                    disabled={disabled}
                                                    placeholder="Taux TVA"
                                                    type="number"
                                                />
                                            )}
                                        />
                                        <label className="font-normal text-sm text-[#646464] pl-1 ">
                                        % TVA
                                        </label>
                                    </div>
                                </div>
                            )
                        })}
                    </div> : null}
                </div>}

            <div className="flex justify-start text-gradient w-full pb-2 pl-2 mb-6 pt-4 border-b border-gray-200">
                Production
            </div>

            <div className="w-full flex flex-row justify-between pt-2 pb-6">
                <div className='flex flex-col'>
                    <div className="mb-1">
                        <Controller
                            name="production_lead"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez sélectionner une ressource'
                                }
                            }}
                            render={({
                                field: { onChange, value, ref },
                                fieldState: { error }
                            }) => (
                                <SelectInput
                                    label='Responsable de production'
                                    placeholder='Responsable de production'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    options={ressources?.options}
                                    hasNextPage={ressources?.hasNextPage}
                                    loading={!ressources}
                                    onChange={onChange}
                                    isSearchable={true}
                                    fetchFunction={handleGetRessources}
                                    labelKeys={['first_name', 'last_name']}
                                    required={true}
                                    disabled={disabled}
                                    blueStyle={blueStyle}
                                />
                            )}
                        />
                    </div>
                    <div className="mb-1">
                        <Controller
                            name="mission_start_date"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez sélectionner une date.'
                                }
                            }}
                            render={({
                                field: { onChange, value },
                                fieldState: { error }
                            }) => (
                                <DateInput
                                    label={'Début de production'}
                                    onChange={onChange}
                                    value={frenchFormat(value)}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className='flex flex-col justify-end'>
                    <div className="mb-1">
                        <Controller
                            name="mission_end_date"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez sélectionner une date.'
                                }
                            }}
                            render={({
                                field: { onChange, value },
                                fieldState: { error }
                            }) => (
                                <DateInput
                                    label={"Fin de production"}
                                    onChange={onChange}
                                    value={frenchFormat(value)}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </div>
                </div>

            </div>
            {isACBC ?
                <div className='w-full pb-4 border-b border-gray-200'>
                    {productionRessourcesValues.map((productionRessource, index) => {

                        return (
                            <div key={productionRessource.id} className='relative'>
                                <div className="w-full flex justify-start gap-4 mb-1">
                                    <div className="flex items-center">
                                        <SelectInput
                                            placeholder='Ressource'
                                            value={productionRessource.ressource}
                                            options={filterOutAlreadySelected(ressources?.options, productionRessourcesValues)}
                                            hasNextPage={ressources?.hasNextPage}
                                            loading={!ressources}
                                            onChange={(e) => handleProductionRessourcesValueChange(e, productionRessource.id, 'ressource')}
                                            isSearchable={true}
                                            fetchFunction={handleGetRessources}
                                            labelKeys={['first_name', 'last_name']}
                                            required={true}
                                            disabled={disabled}
                                            blueStyle={blueStyle}
                                        />

                                    </div>
                                    <div className='flex items-center'>
                                        <TextInput
                                            width='w-[100px]'
                                            label=''
                                            onChange={(e) => handleProductionRessourcesValueChange(e, productionRessource.id, 'daily_rate')}
                                            value={productionRessource.daily_rate}
                                            required={true}
                                            placeholder="TJM"
                                            type="number"
                                        />

                                        <label className=" font-normal text-sm text-[#646464] pl-1 ">
                                            € HT
                                        </label>
                                    </div>
                                </div>
                                <div className='absolute right-[-24px] top-[6px] flex justify-between'>
                                    {
                                        index === 0 ?
                                            null
                                            : <button
                                                onClick={() => handleRemoveProductionRessources(productionRessource.id)}
                                                disabled={disabled}
                                                className="flex items-center justify-center bg-transparent"
                                            >
                                                <Tooltip title='Supprimer'>
                                                    <div>
                                                        <img src={blueStyle ? deleteIconBlue : deleteIcon} alt="logo" className="w-[18px]"/>
                                                    </div>
                                                </Tooltip>
                                            </button>
                                    }
                                </div>
                            </div>
                        )
                    })}
                    <Tooltip title='Ajouter une ressource'>
                        <div
                            onClick={handleAddProductionRessources}
                            className={` cursor-pointer text-sm text-gradient ${productionRessourcesValues.length < 10 ? "text-[#646464]" : "text-[#b2bec3]"} mt-1`}
                        >
                            <p className='hover:underline'>Ajouter une ressource</p>
                        </div>
                    </Tooltip>
                </div>
                :
                <div className='w-full pb-4 border-b border-gray-200'>
                    {productionRessourcesValues.map((productionRessource, index) => {

                        return (
                            <div key={productionRessource.id} className='relative'>
                                <div className="w-full flex justify-between gap-4 mb-1">
                                    <div className="flex items-center">
                                        <SelectInput
                                            placeholder='Ressource'
                                            value={productionRessource.ressource}
                                            options={filterOutAlreadySelected(ressources?.options, productionRessourcesValues)}
                                            hasNextPage={ressources?.hasNextPage}
                                            loading={!ressources}
                                            onChange={(e) => handleProductionRessourcesValueChange(e, productionRessource.id, 'ressource')}
                                            isSearchable={true}
                                            fetchFunction={handleGetRessources}
                                            labelKeys={['first_name', 'last_name']}
                                            required={true}
                                            disabled={index === 0 ? true : disabled}
                                            blueStyle={blueStyle}
                                        />

                                    </div>
                                    <div className='flex items-center'>
                                        <TextInput
                                            // width='w-[100px]'
                                            label=''
                                            onChange={(e) => handleProductionRessourcesValueChange(e, productionRessource.id, 'intervention_days')}
                                            value={productionRessource.intervention_days}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Jours d'intervention"
                                            type="number"
                                        // labelWidth='w-40'
                                        />

                                        <label className="font-normal text-sm text-[#646464] pl-1 ">
                                            J
                                        </label>
                                    </div>
                                    <div className='flex items-center'>
                                        <TextInput
                                            width='w-[100px]'
                                            label=''
                                            onChange={(e) => handleProductionRessourcesValueChange(e, productionRessource.id, 'daily_rate')}
                                            value={productionRessource.daily_rate}
                                            required={true}
                                            placeholder="TJM"
                                            type="number"
                                        />
                                        <label className=" font-normal text-sm text-[#646464] pl-1 ">
                                            € HT
                                        </label>
                                    </div>
                                    <div className='flex items-center'>
                                        <TextInput
                                            width='w-[200px]'
                                            label=''
                                            // onChange={(e) => handleProductionRessourcesValueChange(e, productionRessource.id, 'budget')}
                                            value={productionRessource.budget}
                                            // required={true}
                                            disabled={true}
                                            placeholder="Budget"
                                            type="number"
                                        />
                                        <label className="font-normal text-sm text-[#646464] pl-1 ">
                                            € HT
                                        </label>
                                    </div>
                                </div>
                                <div className='absolute right-[-24px] top-[6px] flex justify-between'>
                                    {
                                        index === 0 ?
                                            null
                                            : <button
                                                onClick={() => handleRemoveProductionRessources(productionRessource.id)}
                                                disabled={disabled}
                                                className="flex items-center justify-center bg-transparent"
                                            >
                                                <Tooltip title='Supprimer'>
                                                    <div>
                                                        <img src={blueStyle ? deleteIconBlue : deleteIcon} alt="logo" className="w-[18px]"/>
                                                    </div>
                                                </Tooltip>
                                            </button>
                                    }
                                </div>
                            </div>
                        )
                    })}
                    <Tooltip title='Ajouter une ressource'>
                        <div
                            onClick={handleAddProductionRessources}
                            className={` cursor-pointer text-sm text-gradient ${productionRessourcesValues.length < 10 ? "text-[#646464]" : "text-[#b2bec3]"} mt-1`}
                        >
                            <p className='hover:underline'>Ajouter une ressource</p>
                        </div>
                    </Tooltip>
                </div>
            }
            {
                isACBC ? null : <div className="w-full flex justify-between mb-1">
                    <div className="font-normal text-m text-[#646464] w-[250px]">
                        TOTAL
                    </div>
                    <div className="font-normal text-m text-[#646464] w-[250px]">
                        {totalInterventionDays} Jours
                    </div>
                    <div className="whitespace-pre-line font-normal text-m text-[#646464] w-[130px]">
                        {(totalBudget / totalInterventionDays ? totalBudget / totalInterventionDays : 0).toFixed(0)}€ HT
                        (TJM moyen)
                    </div>
                    <div className="font-normal text-m text-[#646464] w-[235px]">
                        {totalBudget}€ HT
                    </div>
                </div>
            }
        </>
    );
}

export default BudgetAndProductionModule;
