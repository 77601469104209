import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import dayjs from 'dayjs';

import {DealActions} from '../../../../actions';
import PopUp from '../../../../components/ui/Popup'
import ContainerCard from '../../../../components/ui/ContainerCard/ContainerCard'
import Button from '../../../../components/ui/Button/Button';
import DateInput from '../../../../components/ui/Inputs/DateInput/DateInput';
import {notify} from '../../../../components/ui/Toast/Toast';
import {standardFormat, frenchFormat} from '../../../../utils';
import { MissionActions } from '../../../../actions';

const StartEndDatePopup = ({
    userData,
    view,
    setView,
    dealData,
    refreshDealData,
    refreshMissionsData,
    setNextStep,
    blueStyle = false
}) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    
    // -- react hook form --
    const defaultValues = {
        mission_start_date: dayjs(dealData.mission_start_date),
        mission_end_date: dayjs(dealData.mission_end_date)
    };

    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: defaultValues});

    useEffect(() => {
        if (!view) reset(defaultValues);
    }, [view, refreshDealData]);

    const onSubmit = async (formData) => {
        setDisabled(true);
        setLoading(true);

        const payload = {
            mission_start_date: standardFormat(formData.mission_start_date),
            mission_end_date: standardFormat(formData.mission_end_date),
            status: 500
        }

        const result = await DealActions.updateDeal(userData, payload, dealData.id);

        if (!result.success) {
            notify("Une erreur est survenue lors de la mise à jour de l'affaire");
        }
        else {
            const updatedMissions = dealData.missions.map(async mission => {
                const updatedMission = await MissionActions.updateMissionById(userData, {
                    id: mission.id,
                    status: 1
                });

                return updatedMission;
            });
            const resolveUpdatedMissions = await Promise.all(updatedMissions);

            if (resolveUpdatedMissions.some(mission => !mission.success)) {
                notify("Une erreur est survenue lors de la mise à jour des missions");
            }
            reset(defaultValues);
            await refreshMissionsData();
            await refreshDealData();
            notify("L'affaire a été mise à jour");
            setView(false);
            setLoading(false);
            setDisabled(false);
            setNextStep(true)
        }

    }

    const handleCancel = () => {
        reset(defaultValues);
        setView(false);
    }

    return (
        <PopUp xOverflow="overflow-visible" yOverflow="overflow-visible"  view={view} setView={setView} width="550px">
            <ContainerCard overflow="overflow-visible" title="Dates de production" coloredButtonDisplayed={false}>
                <div className="mb-4">
                    <Controller
                        name="mission_start_date"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez selectionner une date'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <DateInput
                                label={"Début de la mission"}
                                onChange={onChange}
                                value={frenchFormat(value)}
                                error={error}
                                required={true}
                                disabled={disabled}
                                labelWidth='w-40'
                            />
                        )}
                    />
                </div>
                <div className="mb-4">
                    <Controller
                        name="mission_end_date"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez selectionner une date'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <DateInput
                                label={"Fin de la mission"}
                                onChange={onChange}
                                value={frenchFormat(value)}
                                error={error}
                                required={true}
                                disabled={disabled}
                                labelWidth='w-40'
                            />
                        )}
                    />
                
                </div>
                <div className='flex flex-row mx-auto mt-8 w-fit'>
                    <Button
                        onClick={handleCancel}
                        type='white'
                        content='Annuler'
                    />
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        content={"Valider"}
                        loading={loading}
                        disabled={!isValid || disabled}
                        type={blueStyle ? 'blue' : 'primary'}
                        mission/>
                </div>
            </ContainerCard>
        </PopUp>
    )
}

export default StartEndDatePopup;
