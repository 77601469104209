import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import Tooltip from 'antd/lib/tooltip';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { DealActions, MissionActions } from '../../../../actions';
import deleteIcon from '../../../../assets/icons/actionsbutton/delete.png';
import deleteIconBlue from '../../../../assets/icons/blueStyle/delete-blue.png';
import Button from '../../../../components/ui/Button/Button';
import ContainerCard from '../../../../components/ui/ContainerCard/ContainerCard';
import Error from '../../../../components/ui/Error/Error';
import DateInput from '../../../../components/ui/Inputs/DateInput/DateInput';
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import { notify } from '../../../../components/ui/Toast/Toast';
import { frenchFormat } from '../../../../utils';
import BudgetAndProductionModule from '../../popups/dealPopups/PopupModules/businessModules/BudgetAndProductionModule';
import ResponseGroupModule from '../../popups/dealPopups/PopupModules/businessModules/ResponseGroupModule';
import { getErrors, standardFormat } from './../../../../utils';

// --------------------------------------------
// ↓ Components import ↓
// --------------------------------------------
import Popup from '../../../../components/ui/Popup/Popup';
import NewContractorPopup from '../../../contractorsPage/components/popups/NewContractorPopup';

// --------------------------------------------
// ↓ Funciton Body ↓
// --------------------------------------------

const BusinessRoadstepPopup = ({
    dealData,
    refreshData,
    refreshMissionsData,
    view,
    setView,
    userData,
    handleGetContractors,
    contractors,
    handleGetCustomers,
    handleGetDealOrigins,
    handleGetRessources,
    ressources,
    partnerValues,
    setPartnerValues,
    saleValues,
    setSaleValues,
    productionRessourcesValues,
    setProductionRessourcesValues,
    pilotsValues,
    setPilotsValues,
    accordCadreValue
}) => {

    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    
    // states de gestion de l'affichage des inputs
    const [dealStatus, setDealStatus] = useState(null);

    const [newPartnerView, setNewPartnerView] = useState(false);

    const vat = useSelector((state) => state.GlobalsReducer.vat);
    const isACBC = dealData.type === "framework" || dealData.type === "purchase_order";

    // -- react hook form --
    const defaultValues = {
        production_lead: {
            username: userData.userProfileName,
            id: userData.userId,
            name: userData.name
        },
        chance: null,
        budget: null,
        sales: {
            username: userData.userProfileName,
            id: userData.userId,
            name: userData.name
        },
        response_date: dayjs(),
        min_vat_rate: vat,
        vat_rate_global: vat,
        max_vat_rate: vat
        
    };

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { isValid }
    } = useForm({ defaultValues: defaultValues });

    // --------------------------------------------
    // Temporary
    const blueStyle = false;

    // --------------------------------------------
    // ↓ Functions for handling dynamic inputs ↓
    // --------------------------------------------
    
    const handleAddSale = () => {
        if (saleValues.length < 10) {
            setSaleValues((prevState) => [...prevState, { id: Math.random(), percent: '', ressource: null }]);
        }
    }

    const handleRemoveSale = (id) => {
        setSaleValues((prevState) => prevState.filter((element) => element.id !== id));
    }

    const handleSaleValueChange = (e, id, type) => {
        setSaleValues((prevState) => prevState.map((el) => el.id === id ? { ...el, [type]: e } : el));
    }

    const successChanceData = [
        { id: 0, name: 'Inconnue' },
        { id: 1, name: 'Faible' },
        { id: 2, name: 'Moyenne' },
        { id: 3, name: 'Forte' }
    ];

    const statusValue = watch('status');
    const subsidiaryValue = watch('subsidiary');

    // Get dropdows data
    useEffect(() => {

        // ↓ customers dropdown values
        (async () => {
            await handleGetCustomers('', 1);
        })();

        // ↓ deal origins dropdown values
        (async () => {
            await handleGetDealOrigins('', 1);
        })();

        // ↓ ressources dropdown values
        (async () => {
            await handleGetRessources('', 1);
        })();

        // ↓ ressources dropdown values
        (async () => {
            await handleGetContractors('', 1);
        })();

    }, []);

    useEffect(() => {
        setDealStatus(statusValue ? statusValue.id : null);
    }, [statusValue])

    const filterOutAlreadySelected = (options, selected) => {

        if (!options) return;
        const filteredArray = options.filter(obj1 =>
            !selected.some(obj2 =>
                obj1?.name === obj2?.ressource?.name
            )
        );

        return filteredArray;
    };

    const onSubmit = async (data) => {
        
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const createdPayloadSales = saleValues.map((sale) => {
            if (!sale.ressource) return;

            return {
                ressource_id: sale.ressource.id,
                username: sale.ressource.username,
                percent: sale.percent ? sale.percent : 0
            };
        });
        // --------------------------------------------
        // ↓ Response groupement, add deal contractors ↓
        // --------------------------------------------
        let createdPayloadContractors = []
        
        if (partnerValues[0].ressource) {
            createdPayloadContractors = partnerValues.map((partner) => {
                return {
                    budget: data[`partner_budget_${partner.ressource.name}`],
                    direct: data[`payment_${partner.ressource.name}`].direct,
                    contractor: partner.ressource,
                    contractor_id: partner.ressource.id,
                    budget_vat: data[`vat_rate_${partner.ressource.name}`] ? data[`vat_rate_${partner.ressource.name}`] : vat,
                    billable_days: data[`billable_days_${partner.ressource.name}`],
                    is_cocontractor: partner.role.id === 1 ? true : false
        
                }
            })
        }

        let payload = {
            chance: data.chance.id,
            mission_start_date: standardFormat(data.mission_start_date),
            mission_end_date: standardFormat(data.mission_end_date),
            response_date: standardFormat(data.response_date),
            status: 300,
            sales: createdPayloadSales,
            contractors: createdPayloadContractors,
            production_lead_id: data.production_lead.id
        };

        if (isACBC) {
            payload = {
                ...payload,
                min_budget: data.min_budget,
                max_budget: data.max_budget,
                max_vat_rate: data.max_vat_rate ?? vat,
                min_vat_rate: data.min_vat_rate ?? vat,
                min_days_of_intervention: data.min_days_of_intervention,
                max_days_of_intervention: data.max_days_of_intervention
            }
        }

        if (!isACBC) {
            payload = {
                ...payload,
                budget: data.budget_global,
                budget_vat: data.vat_rate_global,
                billable_days: data.billable_days_global,
                commission: data.commission,
                expense_budget: data.expense_budget
            }
        }

        if (!isACBC && dealData.parent) {
            const agreement_leads = pilotsValues.map((partner, index) => {
                return {
                    budget: data[`agreement_budget_${index}`],
                    daily_rate: data[`agreement_daily_rate_${index}`],
                    ressource_id: data[`agreement_lead_${index}`].id
                }
            })
            payload = {
                ...payload,
                agreement_leads
            }
        }

        const dealResult = await DealActions.updateDeal(
            userData,
            payload,
            dealData.id
        );

        if (!dealResult.success) {
            setErrors(getErrors(dealResult));
            setLoading(false);
            setDisabled(false);

            return;
        }

        // --------------------------------------------
        // ↓ For every Ressource added in the form create a mission ↓
        // --------------------------------------------

        const missionsResult = productionRessourcesValues.map(
            async (ressource) => {
                if (!ressource.ressource) return;

                const payload = {
                    deal_id: dealResult.data.id,
                    ressource_id: ressource.ressource.id,
                    billable_days: ressource.intervention_days,
                    daily_rate: ressource.daily_rate
                        ? ressource.daily_rate
                        : 0,
                    status: 0
                };

                return await MissionActions.createMission(
                    userData,
                    payload
                );
            }
        );

        const result = await Promise.all(missionsResult);

        console.log('Missions created:', result);

        setLoading(false);
        setDisabled(false);
        setView(false);
        refreshData();
        refreshMissionsData();
        notify("L'affaire à bien été mise a jour");
    }

    return (
        <Popup xOverflow="overflow-scroll" yOverflow="overflow-visible"  view={view} setView={setView}>
            <ContainerCard
                title="Informations de l'affaire après la remise de l'offre"
                coloredButtonDisplayed={false}
                overflow='overflow-visible'
            >
                <div className="flex flex-col items-center w-full px-4 pb-2 mb-6">
                    <div className="flex justify-start pl-2 text-gradient w-full pb-2 pt-4 mb-6 border-b border-gray-200">
                    Équipe Commerciale
                    </div>
                    {
                        <div className="w-full pb-3 border-b border-gray-200">
                            <div className='w-full'>
                                {
                                    saleValues.map((sale, index) => {
                                        return (
                                            <div
                                                className='flex w-full'
                                                key={index}
                                            >
                                                <div className='w-full flex flex-row justify-between  mb-1.5'>
                                                    <div className='flex w-full'>
                                                        <SelectInput
                                                            label="Commercial"
                                                            placeholder='Choissisez un commercial'
                                                            width={
                                                                index === 0 && dealStatus < 300 ? 'w-[810px]' :
                                                                    index === 0 && dealStatus >= 300 ? "w-[735px]" :
                                                                        index !== 0 && dealStatus < 300 ? "w-[780px]" : 'w-[680px]'}
                                                            value={sale.ressource}
                                                            options={filterOutAlreadySelected(ressources?.options, saleValues)}
                                                            onChange={(e) => handleSaleValueChange(e, sale.id, 'ressource')}
                                                            labelKeys={['first_name', 'last_name']}
                                                            disabled={disabled}
                                                            fetchFunction={handleGetRessources}
                                                            hasNextPage={ressources?.hasNextPage}
                                                            isSearchable={true}
                                                            loading={!ressources}
                                                            labelWidth='w-40'
                                                        />
                                                        <div className="ml-3">
                                                            <TextInput
                                                                label=''
                                                                onChange={(e) => handleSaleValueChange(e, sale.id, 'percent')}
                                                                // value={(e) => e.target.value}
                                                                error={null}
                                                                required={true}
                                                                disabled={disabled}
                                                                placeholder="%"
                                                                type="number"
                                                                width='w-16'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='flex justify-between'>
                                                        {
                                                            index === 0 ?
                                                                null
                                                                : <button
                                                                    onClick={() => handleRemoveSale(sale.id)}
                                                                    disabled={disabled}
                                                                    className="flex items-center justify-center bg-transparent"
                                                                >
                                                                    <Tooltip title='Supprimer'>
                                                                        <div>
                                                                            <img src={blueStyle ? deleteIconBlue : deleteIcon} alt="logo" className="w-[18px]"/>
                                                                        </div>
                                                                    </Tooltip>
                                                                </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                <div className='w-full flex items-center  mb-6'>
                                    <Tooltip title='Ajouter une ressource'>
                                        <button
                                            disabled={!(saleValues.length < 10)}
                                            onClick={handleAddSale}
                                            className={`whitespace-nowrap cursor-pointer text-sm text-gradient ${saleValues.length < 10 ? "text-[#646464]" : "text-[#b2bec3]"}`}
                                        >
                                            <p className='hover:underline'>Ajouter une ressource</p>
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                            <div
                                className='flex justify-between w-full'
                            >
                            </div>
                            <div className={`flex ${dealStatus === 200 ? "justify-start" : "justify-between"} pb-3`}>
                                <Controller
                                    name="response_date"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Veuillez selectionner une date'
                                        }
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error }
                                    }) => (
                                        <DateInput
                                            label={"Date de remise de l'offre"}
                                            onChange={onChange}
                                            value={frenchFormat(value)}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            labelWidth='w-40'
                                        />
                                    )}
                                />
                                <Controller
                                    name="chance"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Veuillez saisir la chance de réussite'
                                        }
                                    }}
                                    render={({
                                        field: { onChange, ref, value },
                                        fieldState: { error }
                                    }) => (
                                        <SelectInput
                                            label='Espérance de réussite'
                                            placeholder='Espérance de réussite'
                                            inputRef={ref}
                                            error={error}
                                            value={value}
                                            options={successChanceData}
                                            onChange={onChange}
                                            labelKeys={['name']}
                                            required={true}
                                            disabled={disabled}
                                            labelWidth='w-40'
                                        />
                                    )}
                                />
                            </div>

                        </div>
                    }
                    <>
                        <ResponseGroupModule
                            control={control}
                            disabled={disabled}
                            watch={watch}
                            partnerValues={partnerValues}
                            setPartnerValues={setPartnerValues}
                            setNewPartnerView={setNewPartnerView}
                            subsidiaryValue={subsidiaryValue}
                            handleGetRessources={handleGetRessources}
                            handleGetContractors={handleGetContractors}
                            contractors={contractors}
                            ressources={ressources}
                            blueStyle={blueStyle}
                        />
                        <BudgetAndProductionModule
                            dealData={dealData}
                            control={control}
                            disabled={disabled}
                            watch={watch}
                            partnerValues={partnerValues}
                            productionRessourcesValues={productionRessourcesValues}
                            pilotsValues={pilotsValues}
                            setPilotsValues={setPilotsValues}
                            setProductionRessourcesValues={setProductionRessourcesValues}
                            ressources={ressources}
                            handleGetRessources={handleGetRessources}
                            blueStyle={blueStyle}
                            accordCadreValue={accordCadreValue}
                            setValue={setValue}
                        />
                    </>
                </div>
                <div className="flex flex-col justify-center pb-2 mt-2">
                    <div className="flex justify-center pb-2">
                        <Button
                            onClick={() => setView(false)}
                            type={'secondary'}
                            content={'Annuler'}
                            disabled={disabled}
                        />
                        <Button
                            type={blueStyle ? 'blue' : 'primary'}
                            content={'Enregistrer'}
                            onClick={handleSubmit(onSubmit)}
                            loading={loading}
                            disabled={!isValid}
                        />
                    </div>
                    <Error errors={errors}/>
                </div>
                <Error errors={errors}/>
            </ContainerCard>
            <Popup
                view={newPartnerView}
                setView={setNewPartnerView}
                width={'1098px'}
                xOverflow=''
                yOverflow='overflow-y-auto'
                maxHeight='45rem'
            >
                <NewContractorPopup
                    setView={setNewPartnerView}
                    userData={userData}
                    toBeUpdated={null}
                    refreshData={handleGetContractors}
                />
            </Popup>
        </Popup>
    );
}

export default BusinessRoadstepPopup;
